<template>
	<a-card class="guest-container">
		<template #title>
			<a-alert message="资源管理" type="info" show-icon closable v-show="dialogState.authAlert === 'open'" @close="closeAlert" class="mb-10">
				<template #icon><bulb-outlined class="alert-icon" /></template>
				<template #description>
					<div class="alert-content">
						无论是某个功能、菜单、按钮的查看权、使用权，还是某些特定数据的访问权限，都属于一种资源（Resource）。
						<br />
						资源支持嵌套树形结构。管理员在这里可以新增、导入、删除、编辑资源，也可以为某资源关联到角色使用。
					</div>
				</template>
			</a-alert>
			<div class="header">
				<a-button @click="backUp" class="mr-15">返回</a-button>
				<a-button type="primary" @click="handleAdd('parent')">新增父级资源</a-button>
				<a-input class="search-input" v-model:value="searchName" @keydown.enter="getDataList" placeholder="请输入名称进行搜索" />
				<a-button type="primary" @click="getDataList">查询</a-button>
			</div>
		</template>

		<a-tabs v-model:activeKey="state.activeKey" @change="changeTab">
			<a-tab-pane key="PC" tab="菜单"></a-tab-pane>
			<a-tab-pane key="FUNC" tab="功能"></a-tab-pane>
		</a-tabs>

		<a-table
			:loading="state.loading"
			:columns="columns"
			rowKey="id"
			childrenColumnName="childrenList"
			:scroll="{ y: tableHeight }"
			:data-source="state.tableData"
			:pagination="false"
		>
			<template #bodyCell="{ column, record, index }">
				<template v-if="column.key === 'status'">
					<el-popconfirm
						confirm-button-text="确认"
						cancel-button-text="取消"
						title="当前操作将修改资源状态，是否继续"
						@confirm="confirm(record)"
						@cancel="cancel"
					>
						<template #reference>
							<el-switch @click="clickSwitch(record)" v-model="record.status" />
						</template>
					</el-popconfirm>
				</template>

				<template v-if="column.key === 'type'">
					<span>{{ record.type === "FUNC" ? "功能" : "菜单" }}</span>
				</template>

				<template v-if="column.key === 'action'">
					<div class="action-buttons">
						<a @click="handleAdd(record)">新增子级资源</a>
						<a @click="handleEdit(record)">编辑</a>
						<a @click="handleDelete(record)" class="delete-btn">删除</a>
						<a v-if="index > 0" @click="handleMove(record, index - 1, 0)">上移</a>
						<a v-if="index < state.tableData.length - 1" @click="handleMove(record, index + 1, 1)">下移</a>
						<a v-if="index > 0" @click="handleMove(record, 0, 0)">置顶</a>
					</div>
				</template>
			</template>
		</a-table>
	</a-card>

	<a-drawer
		:title="formState.edit ? '编辑资源' : formState.isParent ? '新增子级资源' : '新增父级资源'"
		placement="right"
		width="640"
		:visible="dialogState.dialogVisible"
		@close="closeDrawer"
		:closable="true"
	>
		<a-form :model="formState.resource" layout="horizontal" @finish="onFinish" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
			<a-form-item v-if="formState.isParent" label="父级名称" name="parentName">
				<span>{{ formState.resource.parentName }}</span>
			</a-form-item>

			<a-form-item label="资源名称" name="name" :rules="[{ required: true, message: '请输入资源名称' }]">
				<a-input v-model:value="formState.resource.name" />
			</a-form-item>

			<a-form-item label="资源code" name="code" :rules="[{ required: true, message: '请输入资源code' }]">
				<a-input v-model:value="formState.resource.code" />
			</a-form-item>

			<a-form-item label="权限类型">
				<a-radio-group v-model:value="formState.resource.type" :disabled="formState.edit" @change="() => (formState.resource.view = '')">
					<a-radio value="MENU">菜单</a-radio>
					<a-radio value="FUNC">功能</a-radio>
				</a-radio-group>
			</a-form-item>

			<a-form-item v-if="formState.resource.type === 'MENU'" label="菜单类型" name="view">
				<a-select v-model:value="formState.resource.terminal">
					<a-select-option v-for="item in terminalOptions" :key="item.value" :value="item.value">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>

			<a-form-item v-if="formState.resource.type === 'FUNC'" label="路由" name="view">
				<a-select v-model:value="formState.resource.viewList" mode="tags" :token-separators="[',', ' ']" placeholder="输入多个路由，以空格分隔" />
			</a-form-item>

			<a-form-item v-else label="路由" name="view">
				<a-input v-model:value="formState.resource.view" />
			</a-form-item>

			<template v-if="formState.resource.type === 'FUNC' && dialogState.dialogVisible">
				<a-form-item label="应用" name="appCode">
					<SelectApps :app-code="formState.resource.appCode" @change="changeAppCode" />
				</a-form-item>

				<a-form-item label="菜单" name="menuIdList">
					<SelectMenu :authSysCode="route.query.code" @change="changeMenuIdList" :menu-id-list="formState.resource.menuIdList" />
				</a-form-item>
			</template>

			<a-form-item label="图标" name="icon">
				<a-input v-model:value="formState.resource.icon" />
			</a-form-item>

			<a-form-item label="描述" name="note">
				<a-input v-model:value="formState.resource.note" />
			</a-form-item>

			<a-form-item label="应用商户" name="merchant">
				<a-radio-group v-model:value="formState.resource.isCommon">
					<a-radio :value="1">全部</a-radio>
					<a-radio :value="0">指定</a-radio>
				</a-radio-group>
				<div v-if="formState.resource.isCommon === 0" class="mt-10">
					<SelectMerchant v-model:list="formState.resource.assignMerchantIdList" />
				</div>
			</a-form-item>

			<a-form-item :wrapper-col="{ offset: 8, span: 16 }">
				<a-button type="primary" html-type="submit">提交</a-button>
			</a-form-item>
		</a-form>
	</a-drawer>

	<a-modal
		width="800px"
		v-model:visible="copyVisible"
		title="复制资源"
		:confirmLoading="copyLoading"
		@ok="copyResource"
		@cancel="copyVisible = false"
		:style="modalStyle"
	>
		<div>
			<div class="trees-container">
				<div class="tree-section">
					<div class="tree-title">菜单</div>
					<div class="tree-content">
						<a-tree checkable :tree-data="copyMenuList" :checked-keys="checkedMenuKeys" :selectable="false" @check="onMenuCheck" />
					</div>
				</div>
				<div class="tree-section">
					<div class="tree-title">功能</div>
					<div class="tree-content">
						<a-tree checkable :tree-data="copyFuncList" :checked-keys="checkedFunctionKeys" :selectable="false" @check="onFunctionCheck" />
					</div>
				</div>
			</div>
		</div>
	</a-modal>
</template>

<script setup>
import { computed, onActivated, reactive, ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { localGet, localSet, localRemove } from "@/common/utils"
import { message, Modal } from "ant-design-vue"
import SelectApps from "@/components/SelectApps.vue"
import SelectMenu from "@/components/SelectMenu.vue"
import SelectMerchant from "@/components/SelectMerchant.vue"

const router = useRouter()
const route = useRoute()
const store = useStore()

// Table columns configuration
const columns = [
	{ title: "资源名称", dataIndex: "name", width: 150, key: "name" },
	{ title: "资源CODE", dataIndex: "code", key: "code" },
	{ title: "图标", dataIndex: "icon", key: "icon" },
	{ title: "路由", dataIndex: "view", key: "view" },
	{ title: "资源类型", dataIndex: "type", key: "type", width: 100 },
	{ title: "父级菜单", dataIndex: "parentName", key: "parentName" },
	{ title: "资源状态", dataIndex: "status", key: "status", width: 100 },
	{ title: "系统描述", dataIndex: "note", key: "note" },
	{ title: "操作", key: "action", width: 200 },
]

const terminalOptions = [
	{ name: "PC", value: "PC" },
	{ name: "PDA", value: "PDA" },
]

// Reactive state
const state = reactive({
	loading: false,
	activeKey: "PC",
	tableData: [],
	merchantAppId: "",
})

const searchName = ref("")

const params = reactive({
	status: true,
	all: true, //是否需要全部权限
	resourceTypes: ["MENU"], //资源类型 MENU菜单 FUNC功能
	tree: true, //是否需要树形结构
})

const formState = reactive({
	resource: {},
	edit: false,
	isParent: true,
	name: "",
	code: "",
	type: "",
})

const dialogState = reactive({
	dialogVisible: false,
	authAlert: localGet("authAlert") || "open",
})

// Computed
const tableHeight = computed(() => window.innerHeight - 350)

const checkedMenuKeys = ref([])
const checkedFunctionKeys = ref([])

const onMenuCheck = (keys) => {
	checkedMenuKeys.value = keys
}

const onFunctionCheck = (keys) => {
	checkedFunctionKeys.value = keys
}

// Methods
const getDataList = async () => {
	try {
		state.loading = true
		const response = await axios.post(api.resourceList, {
			name: searchName.value,
			...params,
			merchantId: store.getters["user/merchantId"],
			authSysCode: route.query.code,
		})
		state.tableData = response
	} catch (error) {
		console.error("Failed to fetch data:", error)
		message.error("获取数据失败")
	} finally {
		state.loading = false
	}
}

const resetForm = () => {
	formState.resource = {
		name: "",
		code: "",
		note: "",
		merchantId: store.getters["user/merchantId"],
		status: true,
		authSysCode: route.query.code,
		children: [],
		leaf: true,
		param: "",
		parentId: 0, //父节点id *
		sortNum: "",
		type: "MENU",
		viewList: [],
		isCommon: 1,
		assignMerchantIdList: [],
	}
}

const handleAdd = (value) => {
	dialogState.dialogVisible = true
	formState.edit = false

	if (value.id) {
		formState.isParent = true
		formState.resource = {
			parentName: value.name,
			parentId: value.id,
			type: value.type || "MENU",
			isCommon: 1,
		}
	} else {
		formState.isParent = false
		resetForm()
	}
}

const handleEdit = (record) => {
	formState.edit = true
	formState.resource = {
		...record,
		status: true,
		viewList: record.viewList || [],
	}
	dialogState.dialogVisible = true
}

const handleDelete = async (record) => {
	// console.log("record: ", record)
	let name = record.name
	if (record.parentName) {
		name = `${record.parentName}-${name}`
	} else {
		//删除所有子菜单
		name = `${name}下的所有子菜单`
	}
	Modal.confirm({
		title: "确认删除",
		content: `确定要删除【${name}】吗？`,
		onOk: async () => {
			try {
				if (record.childrenList?.length > 0) {
					//先删除子菜单
					for (let i = 0; i < record.childrenList.length; i++) {
						await axios.post(api.resourceDelete, {
							resourceId: record.childrenList[i].id,
						})
					}
				}
				await axios.post(api.resourceDelete, {
					resourceId: record.id,
				})
				message.success("删除成功")
				getDataList()
			} catch (error) {
				console.log("error: ", error)
			}
		},
		onCancel: () => {
			message.info("已取消删除")
		},
	})
}

const handleMove = async (record, index, num) => {
	try {
		const item = state.tableData[index]
		await axios.post(api.resourceSort, {
			resourceId: record.id,
			sortNum: item.sortNum + num,
		})
		message.success("移动成功")
		getDataList()
	} catch (error) {
		message.error("移动失败")
	}
}

const onFinish = async () => {
	try {
		formState.resource.merchantId = store.getters["user/merchantId"]
		formState.resource.authSysCode = route.query.code

		if (formState.resource.type === "FUNC") {
			formState.resource.terminal = ""
		}

		await axios.post(api.resourceSave, {
			resource: formState.resource,
		})

		message.success("保存成功")
		getDataList()
		dialogState.dialogVisible = false
	} catch (error) {
		message.error("保存失败")
	}
}

const clickSwitch = (record) => {
	state.tableData = state.tableData.map((item) => {
		if (item.name === record.name) {
			return { ...item, status: !record.status }
		}
		return item
	})
}

const confirm = async (record) => {
	try {
		await onFinish()
		state.tableData = state.tableData.map((item) => {
			if (item.name === record.name) {
				return { ...item, status: !record.status }
			}
			return item
		})
	} catch (error) {
		message.error("操作失败")
	}
}

const cancel = () => {
	getDataList()
}

const closeAlert = () => {
	dialogState.authAlert = "close"
	localSet("authAlert", "close")
}

const closeDrawer = () => {
	dialogState.dialogVisible = false
}

const backUp = () => {
	router.push({ name: "authSystem" })
}

const changeTab = (value) => {
	params.resourceTypes = [value === "PC" ? "MENU" : "FUNC"]
	getDataList()
}

const changeMenuIdList = (value) => {
	formState.resource.menuIdList = value
}

const changeAppCode = (value) => {
	formState.resource.appCode = value
}

const copyVisible = ref(false)
const copyLoading = ref(false)
const copyMenuList = ref([]) //剪贴板里的List
const copyFuncList = ref([]) //剪贴板里的List

const buildSelectedTree = (nodes, selectedKeys) => {
	return nodes
		.filter((node) => {
			// Keep node if it's selected or has selected children
			const isSelected = selectedKeys.includes(node.code)
			const hasSelectedChildren = node.children?.some(
				(child) => selectedKeys.includes(child.code) || child.children?.some((grandChild) => selectedKeys.includes(grandChild.code))
			)
			return isSelected || hasSelectedChildren
		})
		.map((node) => ({
			...node,
			children: node.children ? buildSelectedTree(node.children, selectedKeys) : undefined,
		}))
}

const copyResource = async () => {
	copyLoading.value = true
	try {
		// Build tree structure for selected menu items
		const selectedMenus = buildSelectedTree(copyMenuList.value, checkedMenuKeys.value)

		// Build tree structure for selected function items
		const selectedFunctions = buildSelectedTree(copyFuncList.value, checkedFunctionKeys.value)

		// Combine all selected items
		const list = [...selectedMenus, ...selectedFunctions]

		if (list.length === 0) {
			message.warning("请选择要复制的资源")
			return
		}

		await axios.post(api.resourceBatchSave, { resourceList: list })
		message.success("创建成功")
		copyVisible.value = false
		localRemove("copyMenuList")
		localRemove("copyFuncList")
		getDataList()
	} catch (error) {
		message.error(`创建失败：${error.message || "未知错误"}`)
	} finally {
		copyLoading.value = false
	}
}

const modalStyle = {
	".ant-modal-body": {
		maxHeight: "80vh",
		overflowY: "auto",
	},
}

onActivated(() => {
	getDataList()

	const menuList = localGet("copyMenuList")
	const funcList = localGet("copyFuncList")
	if (menuList?.length > 0 || funcList?.length > 0) {
		//弹窗确认 检测到有菜单功能复制 是否需要创建
		Modal.confirm({
			title: "剪贴板检测到有菜单功能复制，是否需要创建？",
			onOk: () => {
				copyMenuList.value = getResourceList(menuList)
				copyFuncList.value = getResourceList(funcList)
				copyVisible.value = true
			},
			onCancel: () => {
				console.log("Cancel")
			},
		})
	}
})

const getResourceList = (list) => {
	return list.map((item) => {
		return {
			title: item.name,
			key: item.code,
			name: item.name,
			code: item.code,
			note: item.note,
			merchantId: item.merchantId,
			status: true,
			authSysCode: route.query.code,
			children: item.childrenList?.length > 0 ? getResourceList(item.childrenList) : [],
			leaf: item.leaf,
			parentId: item.childrenList?.length > 0 ? 0 : undefined,
			type: item.type,
			appCode: item.appCode,
			viewList: item.viewList,
			isCommon: item.isCommon,
			assignMerchantIdList: item.assignMerchantIdList,
			terminal: item.terminal,
			view: item.view,
			icon: item.icon,
		}
	})
}
</script>

<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
	padding: 20px;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.search-input {
		width: 180px;
		margin: 0 15px;
	}

	.action-buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 4px 10px;

		a {
			color: #1890ff;
			cursor: pointer;

			&:hover {
				color: #40a9ff;
			}

			&.delete-btn {
				color: #ff4d4f;

				&:hover {
					color: #ff7875;
				}
			}
		}
	}
}

.alert-icon {
	margin-top: 20px;
}

.alert-content {
	font-size: 14px;
	line-height: 1.6;
}

.mb-10 {
	margin-bottom: 10px;
}

.mr-15 {
	margin-right: 15px;
}

.mt-10 {
	margin-top: 10px;
}

:deep(.ant-table) {
	.ant-table-thead > tr > th {
		background: #fafafa;
		font-weight: 500;
	}

	.ant-table-tbody > tr > td {
		padding: 12px 16px;
	}
}

.trees-container {
	display: flex;
	gap: 16px;
	margin: 0 -24px;

	.tree-section {
		flex: 1;
		min-width: 0;
		padding: 0 24px;

		.tree-title {
			font-weight: 500;
			margin-bottom: 8px;
		}

		.tree-content {
			border: 1px solid #d9d9d9;
			border-radius: 2px;
			padding: 8px;
			height: 65vh;
			overflow-y: auto;

			&:hover {
				border-color: #40a9ff;
			}
		}
	}
}

:deep(.ant-modal-body) {
	max-height: 80vh;
	overflow-y: auto;
}
</style>
